import s from './FittersBlock.module.scss'
import { FC, useRef, useState } from 'react'
import { Text, Button, Input, Container } from '@components/ui'
import { FindFitterBlock } from '@generated/model/findFitterBlock'
import { Fitter as FitterType } from '@generated/model/fitter'
import Fitter from './Fitter/Fitter'
import { Search } from '@components/icons'
import Map from './Map/Map'
import { Autocomplete } from '@react-google-maps/api'
import { Product, ProductOption } from '@commerce/types/product'

type FittersBlockProps = {
  data: FindFitterBlock
  fitters: FitterType[]
  showSelectButton?: boolean
  currentFitter: FitterType | null
  setCurrentFitter: (fitter: FitterType) => void
  product?: Product
  setFitterOption?: (option: ProductOption, value: any, e: any) => void
  isLoaded: boolean
}

const FittersBlock: FC<FittersBlockProps> = ({
  data,
  product,
  fitters,
  showSelectButton = false,
  currentFitter,
  setCurrentFitter,
  setFitterOption,
  isLoaded,
}) => {
  const [searchResult, setSearchResult] = useState<any>(null)
  const fittersContainer = useRef<HTMLDivElement>(null)

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined)

  const [orderedFitters, setOrderedFitters] = useState<FitterType[]>(fitters)

  const handleSearch = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const address = formData.get('address') as string

    if (map) {
      var service = new window.google.maps.places.PlacesService(map)

      var request = {
        query: address,
        fields: ['name', 'geometry'],
      }

      service.findPlaceFromQuery(request, function (results, status) {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          results &&
          results.length > 0
        ) {
          const location = results[0].geometry?.location
          if (location) {
            const lat = location.lat()
            const lng = location.lng()
            orderFittersByDistance({ lat, lng })
            map.setCenter(location)
          }
        }
      })
    }
  }

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete)
  }

  function onPlaceChanged() {
    if (searchResult && map) {
      const place = searchResult.getPlace()
      if (place.geometry) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        const orderedFitters = orderFittersByDistance({ lat, lng })

        map.panTo(place.geometry.location)
        map.setZoom(5)
      }
    }
  }

  const handleFitterClick = (fitter: FitterType) => {
    setCurrentFitter(fitter)

    window.requestAnimationFrame(() => {
      map &&
        fitter.latitude &&
        fitter.longitude &&
        map.panTo({
          lat: parseFloat(fitter.latitude),
          lng: parseFloat(fitter.longitude),
        })
      map?.setZoom(9)
    })
  }

  const handleMapMarkerClick = (fitter: FitterType) => {
    setCurrentFitter(fitter)
    fittersContainer.current?.scrollTo({
      top: fitters.findIndex((f) => f.title === fitter.title) * 100,
      behavior: 'smooth',
    })
  }

  const orderFittersByDistance = ({
    lat,
    lng,
  }: {
    lat: number
    lng: number
  }) => {
    function distance(lat1: number, lon1: number, lat2: number, lon2: number) {
      const r = 6371 // km
      const p = Math.PI / 180

      const a =
        0.5 -
        Math.cos((lat2 - lat1) * p) / 2 +
        (Math.cos(lat1 * p) *
          Math.cos(lat2 * p) *
          (1 - Math.cos((lon2 - lon1) * p))) /
          2

      return 2 * r * Math.asin(Math.sqrt(a))
    }

    const fittersByDistance = fitters.sort((a, b) =>
      a.latitude && a.longitude && b.latitude && b.longitude
        ? distance(parseFloat(a.latitude), parseFloat(a.longitude), lat, lng) -
          distance(parseFloat(b.latitude), parseFloat(b.longitude), lat, lng)
        : 0
    )

    setOrderedFitters((state) => [...fittersByDistance])
    setCurrentFitter(fittersByDistance[0])

    return fittersByDistance
  }

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }

          orderFittersByDistance(pos)

          map?.panTo(pos)
          map?.setZoom(9)
        },
        () => {
          console.error('Error: The Geolocation service failed.')
        }
      )
    } else {
      // Browser doesn't support Geolocation
      //   handleLocationError(false, infoWindow, map.getCenter()!)
    }
  }

  return (
    <>
      <Container>
        <section className={s.root}>
          <div className={s.inner}>
            <Text
              html={data.title}
              className={s.title}
              variant="sectionHeadingV2"
            />

            <div className={s.addressContainer}>
              <form onSubmit={handleSearch}>
                <div className={s.inputContainer}>
                  {isLoaded && (
                    <Autocomplete
                      onPlaceChanged={onPlaceChanged}
                      onLoad={onLoad}
                    >
                      <Input
                        type="text"
                        name="address"
                        placeholder={data.address_field_placeholder}
                        className={s.input}
                      />
                    </Autocomplete>
                  )}
                  <Button type="submit" className={s.icon} variant="naked">
                    <Search />
                  </Button>
                </div>
              </form>

              <Button
                className={s.currentLocation}
                variant="ghost"
                onClick={handleLocationClick}
              >
                {data.current_location_title}
              </Button>
            </div>
          </div>

          <div className={s.grid}>
            <div className={s.fittersCol}>
              <div className={s.fittersContainer} ref={fittersContainer}>
                {orderedFitters.map((fitter, index) => (
                  <Fitter
                    key={`fitter-${index}-${fitter.title}`}
                    fitter={fitter}
                    onClick={() => handleFitterClick(fitter)}
                    active={currentFitter?.title === fitter.title}
                    showSelectButton={showSelectButton}
                    setFitterOption={setFitterOption}
                    product={product}
                  />
                ))}
              </div>
            </div>
            <div className={s.mapCol}>
              <div className={s.map}>
                <Map
                  fitters={fitters}
                  setCurrentFitter={handleMapMarkerClick}
                  currentFitter={currentFitter}
                  map={map}
                  setMap={setMap}
                  isLoaded={isLoaded}
                />
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  )
}

export default FittersBlock
