import cn from 'clsx'
import s from './Fitter.module.scss'
import { FC, useState } from 'react'
import { Text, CustomImage, Container, Button } from '@components/ui'

import { Fitter as FitterType } from '@generated/model/fitter'
import { ChevronDown } from '@components/icons'
import { ProductOption } from '@commerce/types/product'
import { Product } from '@commerce/types/product'

type FitterProps = {
  fitter: FitterType
  onClick: () => void
  active: boolean
  showSelectButton: boolean
  product?: Product
  // currentFitter?: FitterType | null
  // setCurrentFitter?: (fitter: FitterType) => void
  setFitterOption?: (option: ProductOption, value: any, e: any) => void
}

const Fitter: FC<FitterProps> = ({
  fitter,
  onClick,
  active = false,
  showSelectButton = false,
  product,
  // currentFitter,
  // setCurrentFitter,
  setFitterOption,
}) => {
  const handleClick = () => {
    onClick()
  }

  const fitterOption =
    (showSelectButton &&
      product &&
      product.options.find(
        (opt: any) => opt.displayName.toLowerCase() === 'fitter'
      )) ||
    undefined

  return (
    <>
      <div className={cn(s.root)} data-state={active ? 'open' : 'closed'}>
        <button className={s.button} onClick={handleClick}>
          <Text className={s.title} html={fitter.title} />
          <ChevronDown className={s.icon} />

          {fitter.address_from_google && fitter.google_place_id ? (
            <div className={s.addressTitle}>
              <Text
                html={fitter.address_from_google.split(',').join('<br>')}
                variant="smallText"
              />
            </div>
          ) : (
            <>
              {fitter.address && (
                <div className={s.addressTitle}>
                  <Text html={fitter.address} variant="smallText" />
                </div>
              )}
            </>
          )}
        </button>

        <div className={cn(s.addressBlock, { [s.active]: active })}>
          {fitter.phone_from_google && (
            <Text className={s.addressLine} variant="smallText">
              {fitter.phone_from_google}
            </Text>
          )}
          {fitter.hours_from_google && (
            <Text
              className={s.addressLine}
              variant="smallText"
              html={fitter.hours_from_google}
            />
          )}

          {showSelectButton && setFitterOption && fitterOption && (
            <Button
              onClick={(e) =>
                setFitterOption(
                  fitterOption,
                  { label: fitter.slug.toLowerCase() },
                  e
                )
              }
              className={s.buttonBlue}
            >
              Select
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Fitter
