import cn from 'clsx'
import s from './FAQ.module.scss'
import { Text, useUI } from '@components/ui'
import { HomepageFaqblock as HomepageFAQBlockType } from '@generated/model/homepageFaqblock'
import { CustomImageProps } from '@components/ui/CustomImage/CustomImage'
import { useHomepageContext } from '@context/HomepageContext'
import * as Accordion from '@radix-ui/react-accordion'

type FAQProps = {
  title: string
  faqs: HomepageFAQBlockType['faqs']
  expandAll?: boolean
}

const FAQ = ({ title, faqs, expandAll = true }: FAQProps) => {
  const { pageTheme } = useUI()

  return (
    <>
      <div
        className={cn(s.root, {
          [s.faqBlackBorder]: pageTheme === 'LIGHT',
          [s.disabled]: expandAll,
        })}
        id="faq"
      >
        <div className={s.contentContainer}>
          <Text html={title} className={s.title} variant="heading" />

          <div className={s.questions}>
            <Accordion.Root
              type="multiple"
              className={s.AccordionRoot}
              defaultValue={
                (expandAll && faqs?.map((_, index) => `faq_${index}`)) || []
              }
            >
              {faqs &&
                faqs.map((faq, index: number) => (
                  <Accordion.Item
                    className={cn(s.faq, s.AccordionItem)}
                    key={`faq_${index}`}
                    value={`faq_${index}`}
                    disabled={expandAll}
                  >
                    <Accordion.Header className={s.AccordionHeader}>
                      <Accordion.Trigger className={s.AccordionTrigger}>
                        <Text
                          html={faq.title}
                          className={s.faqTitle}
                          variant="text-3"
                        />
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content className={s.AccordionContent}>
                      <Text html={faq.detail} className={s.detail} />
                    </Accordion.Content>
                  </Accordion.Item>
                ))}
            </Accordion.Root>
          </div>
        </div>
      </div>
    </>
  )
}

export default FAQ
