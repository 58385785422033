const Search = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="search">
        <path
          id="Vector"
          d="M11.166 19.3667C15.5843 19.3667 19.166 15.785 19.166 11.3667C19.166 6.94842 15.5843 3.3667 11.166 3.3667C6.74774 3.3667 3.16602 6.94842 3.16602 11.3667C3.16602 15.785 6.74774 19.3667 11.166 19.3667Z"
          stroke="#939393"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M21.1664 21.3666L16.8164 17.0166"
          stroke="#939393"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Search
