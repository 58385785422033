import cn from 'clsx'
import s from './How.module.scss'
import { FC } from 'react'
import { Text, CustomImage, Container, Button } from '@components/ui'
import { isMobileWidth } from '@utils/helpers'
import { useWindowSize } from '@hooks/useWindowSize'
import { LoreTwoPageHowSection } from '@generated/model/loreTwoPageHowSection'
import Image from 'next/image'

type HowProps = {
  data: LoreTwoPageHowSection
}

const How: FC<HowProps> = ({ data }) => {
  const windowSize = useWindowSize()
  const isMobile = isMobileWidth(windowSize.width)

  return (
    <>
      <Container>
        <section className={s.root}>
          <div className={s.grid}>
            <Text
              html={data.title}
              className={s.title}
              variant="sectionHeadingV2"
            />

            <div className={s.stepContainer}>
              <div className={s.step}>
                <div className={s.stepTitle}>
                  <Text variant="sectionHeadingV2">
                    <span>01</span> {data.step_1_title}
                  </Text>
                  <div className={s.arrow}>
                    <Image src="/white-arrow.svg" width={50} height={44} />
                  </div>
                </div>
                <Text className={s.stepDetail} html={data.step_1_detail} />
              </div>

              <div className={s.step}>
                <div className={s.stepTitle}>
                  <Text variant="sectionHeadingV2">
                    <span>02</span> {data.step_2_title}
                  </Text>
                  <div className={s.arrow}>
                    <Image src="/white-arrow.svg" width={50} height={44} />
                  </div>
                </div>
                <Text html={data.step_2_detail} />
              </div>

              <div className={s.step}>
                <div className={s.stepTitle}>
                  <Text variant="sectionHeadingV2">
                    <span>03</span> {data.step_3_title}
                  </Text>
                  <div className={s.arrow}>
                    <Image src="/white-arrow.svg" width={50} height={44} />
                  </div>
                </div>
                <Text html={data.step_3_detail} />
              </div>

              <div className={s.step}>
                <div className={s.stepTitle}>
                  <Text variant="sectionHeadingV2">
                    <span>04</span> {data.step_4_title}
                  </Text>
                  <div className={s.arrow}>
                    <Image src="/white-arrow.svg" width={50} height={44} />
                  </div>
                </div>
                <Text html={data.step_4_detail} />
              </div>
            </div>
            <Text html={data.detail} className={cn(s.detail)} />
          </div>
        </section>
      </Container>
    </>
  )
}

export default How
